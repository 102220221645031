/* --------------------------------------------------------------------
Import accessibility helpers
--------------------------------------------------------------------*/
import { toggleAriaExpanded, addTabIndex, removeTabIndex  } from './accessibility.js';
import { hideHeader, showHeader, showBackToTop  } from './tools.js';


/* --------------------------------------------------------------------
Handle Burger Menu
--------------------------------------------------------------------*/
const bodyTag   = document.getElementsByTagName("body");
const mainTag   = document.getElementById("main-content");
const burger    = document.getElementsByClassName('hamburger');
const navMenu   = document.querySelector('.mobile-nav');
const topNavBar   = document.querySelector('.topnavbar');
const hdr       = document.getElementById('hdr');
// const anchorLinks = document.getElementsByClassName('is-anchor');

let navMenuIsAnimating = false;

let isMenuOpen = false;

if( window.outerWidth > 1023 ){
    addTabIndex('has-children');
}
else{
    removeTabIndex('has-children');
}


mainTag.addEventListener("click", function() {
    if(isMenuOpen){
        if(burger.length !== 0){
            burger[0].click();
        }
    }

}, false);

if(burger.length !== 0){
    burger[0].addEventListener("click", function() {
        if(navMenuIsAnimating){
            return true;
        }
        else{
            const burgerBtn = this;

            toggleAriaExpanded( burgerBtn );

             navMenu.classList.remove(
                'w-0',
                'hidden'
            );

            window.setTimeout(function(){
                isMenuOpen = (isMenuOpen == false) ? true : false;

                navMenu.classList.add('duration-700');
                bodyTag[0].classList.toggle("overflow-hidden");
                burgerBtn.classList.toggle("is-active");

                if(isMenuOpen){
                    navMenu.scrollTop = 0;
                    navMenu.classList.remove(
                        '-translate-y-full',
                        'w-0',
                        'hidden'
                    );
                    hdr.classList.add('bg-blue-dark');
                    topNavBar.classList.add('bg-blue-dark');
                    removeTabIndex( 'main-link' );
                }
                else{
                    topNavBar.classList.remove('bg-blue-dark');
                    navMenu.classList.add('-translate-y-full');
                    addTabIndex( 'main-link' );
                }
            }, 5);
        }

    }, false);


    /* ------------------------------------------------------------
    List for key board input
    • If ESC key pressed, close menu
    ------------------------------------------------------------ */
    document.addEventListener("keyup", (e) => {
        if(!global.searchOpen){
            if (e.key === "Escape") {
                burger[0].classList.remove("is-active");
                bodyTag[0].classList.remove("overflow-hidden");
                navMenu.classList.add(
                    'duration-700',
                    '-translate-y-full'
                );

                addTabIndex( 'main-link' );
                isMenuOpen = false;
            }
        }
    });

    /* ------------------------------------------------------------
    List for transition end on navigation menu, when complete
    remove transition duration.
    ------------------------------------------------------------ */
    navMenu.ontransitionrun = () => {
        navMenuIsAnimating = true;
    };

    navMenu.ontransitionend = () => {
        navMenuIsAnimating = false;
        navMenu.classList.remove('duration-700');

        if(!isMenuOpen){
            navMenu.classList.add(
                'w-0',
                'hidden'
            );
            hdr.classList.remove('bg-blue-dark');
        }
    };
}

/* --------------------------------------------------------------------
Expand / Collapse main navigation sub pages
--------------------------------------------------------------------*/
// const expandableBtn = document.querySelectorAll('.has-children');

// if(expandableBtn){

//     expandableBtn.forEach(function( button ){
//         button.onclick = function(){
//             button.classList.toggle('has-children');

//             if( button.children[1].classList.contains('rotate-90') ){
//                 button.children[1].classList.remove('rotate-90');
//                 button.children[1].classList.add('-rotate-90');
//             }
//             else{
//                 button.children[1].classList.remove('-rotate-90');
//                 button.children[1].classList.add('rotate-90');
//             }

//             if(button.nextElementSibling){
//                 button.nextElementSibling.classList.toggle('hidden');
//             }
//         };
//     });
// }

/* --------------------------------------------------------------------
If the navigation contains anchor links, be sure to close the
burger menu when on is click on a mobile device.
--------------------------------------------------------------------*/
// if (anchorLinks.length !== 0) {
//     for (var i = anchorLinks.length - 1; i >= 0; i--) {
//         anchorLinks[i].addEventListener('click', function(e){
//             console.log('Anchor Click');
//             if(isMenuOpen){
//                 if(burger.length !== 0){
//                 burger[0].click();
//             }
//         }
//         });
//     };
// };